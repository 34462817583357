@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe:ital@0;1&family=Caveat:wght@400;500;600;700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300;0,400;1,300&display=swap');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

* { margin: 0px; padding: 0px; outline: 0px; }

:root {  
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  h1 { font-size: 4rem; line-height: 4.1rem; font-weight: 700; }
  h2 { font-size: 2rem; line-height: 2.1rem; font-weight: 400; }
  h3 { font-size: 1.5rem; line-height: 2rem; font-weight: 400; }
  p { font-size: inherit; line-height: inherit; font-weight: 300; }
  small { font-size: .7rem; line-height: .7rem; font-weight: 300; }
  a { text-decoration: none; }

  .text-center { text-align: center; }
  .text-left { text-align: left; }
  .text-right { text-align: right; }

  .flex-align-center { align-items: center; }
  .flex-align-left { align-items: start; }
  .flex-align-right { align-items: end; }

  ol {
    margin: 0 0 0 1rem;
    li {
      margin: 0.5rem 0 0 0;
      b {

      }
    }
  }
  ul {
    margin: 0 0 0 1rem;
    b {
      
    }
    li {
      margin: 0.5rem 0 0 0;
    }
  }

  .section {
    background-color: #101010;
    color: #ffffff;
  }
  .invert {
    filter: invert(1);
  }

  .ace-tabs {
      margin: 0px;
      border-bottom: 1px solid #FFA800;
      .react-tabs__tab {
        display: inline-block;
        border: 0px;
        border: 1px solid transparent;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
        &.react-tabs__tab--selected {
          background: #FFA800;
          border-bottom: 1px solid #FFA800;
          bottom: -1px;
          color: #fff;
          border-radius: 5px 5px 0 0;
        }
      }
  }

  .view-360 {
    margin: 0px;
    padding: 0px 5px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #aaaaaa;
    .react-tabs__tab {
      &.react-tabs__tab--selected {
        color: #ffaa00;
        font-weight: 600;
      }
    }
  }

  .slick-arrow {
    z-index: 10;
    &.slick-prev { left: 0px; }
    &.slick-next { right: 0px; }
  }



  @for $i from 0 through 100 {
    .width-#{$i} {
      width: $i * 1% !important;
    }
    .m-#{$i} {
      margin: $i * 1rem !important;
    }
    .mt-#{$i} {
      margin-top: $i / 10 * 1rem !important;
    }
    .mb-#{$i} {
      margin-bottom: $i / 10 * 1rem !important;
    }
    .mh-#{$i} {
      margin: 0 $i / 10 * 1rem !important;
    }
    .mv-#{$i} {
      margin: $i / 10 * 1rem 0 !important;
    }
    .pt-#{$i} {
      padding-top: $i / 10 * 1rem !important;
    }
    .pb-#{$i} {
      padding-bottom: $i / 10 * 1rem !important;
    }
    .ph-#{$i} {
      padding: 0 $i / 10 * 1rem !important;
    }
    .pv-#{$i} {
      padding: $i / 10 * 1rem 0 !important;
    }
    .p-#{$i} {
      padding: $i / 10 * 1rem !important;
    }
    .maxw-#{$i} {
      max-width: $i * 1% !important;
    }
    .minw-#{$i} {
      min-width: $i * 1% !important;
    }
    .gap-#{$i} {
      gap: calc($i / 10) * 1rem !important;
    }
  }
}

@media (max-width: 1024px) {
  body {  
    h1 { font-size: 12vw; line-height: 12.5vw; font-weight: 700; }
  }
}

@keyframes slideFooter {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-300vw, 0, 0); /* The image width */
  }
}




// https://react-icons.github.io/react-icons/icons/io5/